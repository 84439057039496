import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Id from "./scenes/id";
import Logs from "./scenes/log";
import Login from "./scenes/auth/Login";
// import Invoices from "./scenes/invoices";
// import Contacts from "./scenes/contacts";
// import Bar from "./scenes/bar";
// import Form from "./scenes/form";
// import Line from "./scenes/line";
// import Pie from "./scenes/pie";
// import FAQ from "./scenes/faq";
// import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import EventBus from "./common/EventBus";
// import Calendar from "./scenes/calendar/calendar";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  const {user: currentUser} = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    if (["/login"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
      // const history = useHistory();
      // history.push('/login');
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {currentUser ? (
          <div className="app">
            <Sidebar isSidebar={isSidebar}/>
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar}/>
              <Routes>
                <Route path="/" element={<Dashboard/>}/>
                <Route path="/id" element={<Id/>}/>
                <Route path="/logs" element={<Logs />} />
                {/*<Route path="/contacts" element={<Contacts />} />*/}
                {/*<Route path="/invoices" element={<Invoices />} />*/}
                {/*<Route path="/form" element={<Form />} />*/}
                {/*<Route path="/bar" element={<Bar />} />*/}
                {/*<Route path="/pie" element={<Pie />} />*/}
                {/*<Route path="/line" element={<Line />} />*/}
                {/*<Route path="/faq" element={<FAQ />} />*/}
                {/*<Route path="/calendar" element={<Calendar />} />*/}
                {/*<Route path="/geography" element={<Geography />} />*/}
              </Routes>
            </main>
          </div>
        ) : (
          <div className="app">
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar}/>
              <Routes>
                <Route path="/login" element={<Login/>}/>
              </Routes>
            </main>
          </div>
        )}

      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
