import {Box, useTheme, Button, IconButton, Stack} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import DownloadCSV from "../../helpers/DownloadCSV";
import { useEffect, useReducer, useState, ChangeEvent } from "react";
import EventBus from "../../common/EventBus";
import styles from './log.css'
import { useNavigate } from "react-router-dom";
import moment from "moment";

const swServiceAdminUrls = process.env.REACT_APP_SW_SERVICE_ADMIN_URL
const token = process.env.REACT_APP_SW_SERVICE_KEY

const Popup = ({ onClose, onSave, tableData }) => {
    const [inputValue, setInputValue] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [newSave, setNewSave] = useState(true);
    const [errors, setErrors] = useState({key:false,message:''});
    const handleSave = () => {
        // Call the onSave callback with input value and checkbox state
        onSave(inputValue, isChecked);
        // Close the popup
        onClose();
    };

    return (
        <div className={styles.overlay}>
            <div className={styles.popup}>
            <h2>Create new key</h2>
            <label>
                Key:
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => {
                        if (e.target.value.match(/[^A-Za-z\d]/) === null) {
                            if(e.target.value.length<9) {
                                setInputValue(e.target.value)
                                if(e.target.value.length===8) {
                                    if(tableData.find((item) => item.date===e.target.value)) {
                                        setErrors({key:true,message:'This key already exists!'})
                                    }else {
                                        setErrors({key:false,message:''})
                                        setNewSave(false)
                                    }
                                }else {
                                    setErrors({key:false,message:''})
                                    setNewSave(true)
                                }
                            }
                        }
                    }}
                /> {errors.key && <span>{errors.message}</span>}
            </label><br/>
            <label>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                />
                Active
            </label>
            <button disabled={newSave} onClick={handleSave}>Save</button>
        </div>
        </div>
    );
}

/**
 * @type {{ selected: number | null, arr: number[] }}
 */
const initialState = {
    token: null
};



/**
 * @param {typeof initialState}
 * I assume this won't actually just be like ADD_ITEM with no payload, but anyway:
 * @param {{ type: "ADD_TOKEN", value: token }}
 * @returns {typeof initialState}
 */
function reducer(state, action) {
    console.log(action)
    switch (action.type) {
        case "ADD_TOKEN":
            // I assume this will not be quite like this:
            return {
                token: action.value
            };
      // I assume there will be other actions...
      // case "REMOVE_ITEM":
      //   return altered state
        default:
            throw new Error();
    }
}
const Logs = () => {
    type cvsItem = {
        id: string;
        valid: string;
        date: string;
        delay: string;
    };
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isLoaded, setIsLoaded] = useState(false);
    const [creatingNew, setCreatingNew] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [tableData, setTableData] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const nav = useNavigate();

    const [csvData, setCsvData] = useState([]);
    const [filename, setFilename] = useState("");

    const addToken = async (token) => {
        await dispatch({ type: "ADD_TOKEN", value: token });
    };

    const   columns = [
        {
            field: "date",
            headerName: "Date & time",
            type: "string",
            headerAlign: "left",
            align: "left",
            width: 150,
            valueFormatter: (value?: number) => {
                if (value == null) {
                    return '';
                }
                // console.log(value, value.value, moment(Number(value.value)), moment(value.value).format('YYYY-MM-DD h:mm:ss A'));
                // return value.value
                return `${moment(Number(value.value)).format('YYYY-MM-DD h:mm:ss A')}`;
            },
        },
        {
            field: "chipId",
            headerName: "FTDI ChipID",
            type: "string",
            headerAlign: "left",
            align: "left",
            renderCell: (params) => {
                return params.value.toUpperCase()
            },
        },
        {
            field: "serial",
            headerName: "Serial",
            type: "string",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            headerAlign: "left",
            align: "left",
            width:200
        },
        {
            field: "chipIdGoodBad",
            headerName: "ChipID good/bad",
            type: "string",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "fwVer",
            headerName: "FW version",
            type: "string",
            headerAlign: "left",
            align: "left",
        },

        {
            field: "client",
            headerName: "Client IP",
            type: "string",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "agent",
            headerName: "User agent",
            type: "string",
            headerAlign: "left",
            align: "left",
        },

    ];
    const dataProvider = {
        getList: async (resource, params) => {
            console.log('fetch')
            console.log(`${swServiceAdminUrls}/id`)
            console.log(state);
            const request = new Request(`${swServiceAdminUrls}/log?access=r&resource=sw-service&key=nesto`, {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("token")}` }),
            });
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                EventBus.dispatch("logout");
                nav("/login");
                throw new Error(response.statusText);
            }
            const data = await response.json();
            console.log(data);
            return data;
        }, // get a list of records based on sort, filter, and pagination
    }
    const [clickedRow, setClickedRow] = useState();
    const openPopup = () => {
        // Define a callback function to handle the save action
        setCreatingNew(!creatingNew)
    };
    const onSaveCallback = (inputValue, isChecked) => {
        // Perform actions with input value and checkbox state
        dataProvider.create({ key:inputValue, active: isChecked}).then(()=>{
            const newData = [...tableData, {id:null, key:inputValue, active: isChecked}];
            setTableData(newData)
        })

    };
    const onCloseCallback = (inputValue, isChecked) => {
        openPopup()
    };


    const useFetch =  (fetch) => {
        useEffect(() => {

            const fetchData = async function () {
                console.log('get token')
                console.log(localStorage.getItem("token"))
                await addToken(localStorage.getItem("token"))
                console.log(token)
                try {
                    setIsLoaded(false);
                    console.log('load once on mount')
                    const data = await dataProvider.getList();
                    setTableData(data)
                } catch (error) {
                    throw error;
                } finally {
                    setIsLoaded(true)
                }
            };
            fetchData();
        }, [fetch]);
    };

    useFetch(true)



    return (
        <Box m="20px">
            {creatingNew && (
                <Popup onSave={onSaveCallback} onClose={onCloseCallback} tableData={tableData}></Popup>
            )}
            <Header title="Logs" subtitle="Review logs"/>
            <Stack direction="row" spacing={1}>
                <DownloadCSV data={tableData} fileName={'keyData'}/>
            </Stack>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                {isLoaded && (
                    <DataGrid rows={tableData} columns={columns}
                                  getRowId={(row) =>  row.date + row.chipId}
                              pageSizeOptions={[5, 10, 25]}
                              autosizeOptions={{
                                  columns: ['chipId', 'serial', 'description', 'chipIdGoodBad', 'fwVer', 'date', 'client'],
                                  includeOutliers: true,
                                  includeHeaders: false,
                              }}
                              initialState={{
                                  columns: {
                                      columnVisibilityModel: {
                                          // Hide columns status and traderName, the other columns will remain visible
                                          agent: false,
                                      },
                                  },
                              }}/>
                )}
            </Box>
        </Box>
    );
};

export default Logs;
